import React, { useRef, useEffect, useState } from 'react';
import './index.css';
import fourth1Web from '../../assets/videos/web/4-1-web.mp4';
import fourth2Web from '../../assets/videos/web/4-2-web.mp4';
import fourth3Web from '../../assets/videos/web/4-3-web.mp4';

const Fourth = () => {
  const [isInViewport, setIsInViewport] = useState(false);
  const [isInViewportFirst, setIsInViewportFirst] = useState(false);
  const [isInViewportSecond, setIsInViewportSecond] = useState(false);
  const [isInViewportThird, setIsInViewportThird] = useState(false);

  const splineRef = useRef(null);
  const splineRefFirst = useRef(null);
  const splineRefSecond = useRef(null);
  const splineRefThird = useRef(null);

  const handleIntersectionFirst = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setIsInViewportFirst(true);
      } else {
        setIsInViewportFirst(false);
      }
    });
  };

  const handleIntersectionSecond = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setIsInViewportSecond(true);
      } else {
        setIsInViewportSecond(false);
      }
    });
  };

  const handleIntersectionThird = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setIsInViewportThird(true);
      } else {
        setIsInViewportThird(false);
      }
    });
  };

  const handleIntersection = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setIsInViewport(true);
      } else {
        setIsInViewport(false);
      }
    });
  };

  // Lazy Loading - 뷰포트에 있을 때 렌더링
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (splineRef.current) {
      observer.observe(splineRef.current);
    }
    return () => {
      if (splineRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(splineRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observerFirst = new IntersectionObserver(
      handleIntersectionFirst,
      options,
    );

    if (splineRefFirst.current) {
      observerFirst.observe(splineRefFirst.current);
    }
    return () => {
      if (splineRefFirst.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observerFirst.unobserve(splineRefFirst.current);
      }
    };
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observerSecond = new IntersectionObserver(
      handleIntersectionSecond,
      options,
    );

    if (splineRefSecond.current) {
      observerSecond.observe(splineRefSecond.current);
    }
    return () => {
      if (splineRefSecond.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observerSecond.unobserve(splineRefSecond.current);
      }
    };
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observerThird = new IntersectionObserver(
      handleIntersectionThird,
      options,
    );

    if (splineRefThird.current) {
      observerThird.observe(splineRefThird.current);
    }
    return () => {
      if (splineRefThird.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observerThird.unobserve(splineRefThird.current);
      }
    };
  }, []);

  const handleRadar = () => {
    window.open('https://www.radarshift.com', '_blank');
  };

  const handleBasic = () => {
    window.open('https://custellax.com/', '_blank');
  };
  const handleSilo = () => {
    window.open('https://silo.mindshift.co.kr/', '_blank');
  };

  return (
    <>
      <section className="w-full h-screen bg-black relative flex flex-col items-center px-[120px] lg:pt-[96px] xl:pt-[188px]">
        <div className="relative flex flex-col">
          <div className="flex flex-row justify-between gap-20 items-center">
            {/* Radarshift */}
            <div className="hidden h-full bg-black justify-center px-1 py-1 flex-shrink-1">
              <div
                className="relative flex items-center justify-center xs:hidden sm:hidden"
                ref={splineRef}
              >
                {isInViewport && (
                  <div
                    style={{
                      width: '250px',
                      height: '250px',
                      transition: 'opacity 0.5s ease-in-out',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <video
                      muted="muted"
                      autoplay="autoplay"
                      loop="loop"
                      playsInline
                    >
                      <source src={fourth1Web} type="video/mp4" />
                    </video>
                  </div>
                )}
              </div>
              <div
                className="relative flex items-center justify-center lg:hidden"
                ref={splineRefFirst}
              >
                <div
                  style={{
                    width: '250px',
                    height: '250px',
                    transition: 'opacity 0.5s ease-in-out',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <video
                    muted="muted"
                    autoplay="autoplay"
                    loop="loop"
                    playsInline
                  >
                    <source src={fourth1Web} type="video/mp4" />
                  </video>
                </div>
              </div>
              <div className="flex flex-col items-center text-center xs:mt-[24px] sm:mt-[24px] lg:mt-[50px]">
                <p className="font-SubTitle text-white xs:text-[16px] sm:text-[20px] text-[28px] sm:mt-[48px] xs:mt-[48px]">
                  Radarshift
                </p>
                <p className="font-SubDetail text-primary xs:text-[12px] sm:text-[16px] lg:text-[18px] xs:mt-[16px] sm:mt-[16px] lg:mt-[16px]">
                  Industry's first domestic Politically
                  <br />
                  Exposed Person (PEP) filtering service
                </p>
                <button
                  onClick={handleRadar}
                  className="grad-btn bg-black xs:w-24 xs:h-9 sm:w-24 sm:h-9 xs:rounded-[24px] sm:rounded-[24px] w-[108px] h-9  border-1 lg:rounded-[42px] text-white xs:text-[12px] xs:mt-[24px] sm:text-[14px] sm:mt-[24px] text-[14px] mt-[52px] flex items-center justify-center"
                >
                  <div className="bg-black xs:w-[92px] xs:h-[32px] sm:w-[92px] sm:h-[32px] xs:rounded-[24px] sm:rounded-[24px] w-[104px] h-8 rounded-[42px] border-none flex justify-center items-center">
                    Explore
                  </div>
                </button>
              </div>
            </div>
            {/* Custella */}
            <div className="h-full justify-center px-1 py-1 flex-shrink-1">
              <div
                className="relative flex items-center justify-center  xs:hidden sm:hidden"
                ref={splineRef}
              >
                {isInViewport && (
                  <div
                    style={{
                      width: '250px',
                      height: '250px',
                      transition: 'opacity 0.5s ease-in-out',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <video
                      muted="muted"
                      autoplay="autoplay"
                      loop="loop"
                      playsInline
                    >
                      <source src={fourth2Web} type="video/mp4" />
                    </video>
                  </div>
                )}
              </div>
              <div
                className="relative flex items-center justify-center lg:hidden"
                ref={splineRefSecond}
              >
                {isInViewportSecond && (
                  <div
                    style={{
                      width: '250px',
                      height: '250px',
                      transition: 'opacity 0.5s ease-in-out',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <video
                      muted="muted"
                      autoplay="autoplay"
                      loop="loop"
                      playsInline
                    >
                      <source src={fourth2Web} type="video/mp4" />
                    </video>
                  </div>
                )}
              </div>
              <div className="bg-black flex flex-col items-center text-center xs:mt-[24px] sm:mt-[24px] lg:mt-[50px]">
                <p className="font-SubTitle text-white xs:text-[16px] sm:text-[20px] text-[28px] sm:mt-[48px] xs:mt-[48px]">
                  custella
                </p>
                <p className="font-SubDetail text-primary xs:text-[12px] sm:text-[16px] lg:text-[18px] xs:mt-[16px] sm:mt-[16px] lg:mt-[16px]">
                  Safe and reliable digital assets
                  <br />
                  Custody solution
                </p>
                <button
                  onClick={handleBasic}
                  className="grad-btn bg-black xs:w-24 xs:h-9 sm:w-24 sm:h-9 xs:rounded-[24px] sm:rounded-[24px] w-[108px] h-9  border-1 lg:rounded-[42px] text-white xs:text-[12px] xs:mt-[24px] sm:text-[14px] sm:mt-[24px] text-[14px] mt-[52px] flex items-center justify-center"
                >
                  <div className="bg-black xs:w-[92px] xs:h-[32px] sm:w-[92px] sm:h-[32px] xs:rounded-[24px] sm:rounded-[24px] w-[104px] h-8 rounded-[42px] border-none flex justify-center items-center">
                    Explore
                  </div>
                </button>
              </div>
            </div>
            {/* silo */}
            <div className="h-full justify-center px-1 py-1 flex-shrink-1">
              <div
                className="relative flex items-center justify-center  xs:hidden sm:hidden"
                ref={splineRef}
              >
                <div
                  style={{
                    width: '250px',
                    height: '250px',
                    transition: 'opacity 0.5s ease-in-out',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <video
                    muted="muted"
                    autoplay="autoplay"
                    loop="loop"
                    playsInline
                  >
                    <source src={fourth3Web} type="video/mp4" />
                  </video>
                </div>
              </div>
              <div
                className="relative flex items-center justify-center lg:hidden"
                ref={splineRefSecond}
              >
                <div
                  style={{
                    width: '250px',
                    height: '250px',
                    transition: 'opacity 0.5s ease-in-out',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <video
                    muted="muted"
                    autoplay="autoplay"
                    loop="loop"
                    playsInline
                  >
                    <source src={fourth3Web} type="video/mp4" />
                  </video>
                </div>
              </div>
              <div className="bg-black flex flex-col items-center text-center xs:mt-[24px] sm:mt-[24px] lg:mt-[50px]">
                <p className="font-SubTitle text-white xs:text-[16px] sm:text-[20px] text-[28px] sm:mt-[48px] xs:mt-[48px]">
                  Silo
                </p>
                <p className="font-SubDetail text-primary xs:text-[12px] sm:text-[16px] lg:text-[18px] xs:mt-[16px] sm:mt-[16px] lg:mt-[16px]">
                  Hassle-free Bitcoin
                  <br />
                  Mining Platform
                </p>
                <button
                  onClick={handleSilo}
                  className="grad-btn bg-black xs:w-24 xs:h-9 sm:w-24 sm:h-9 xs:rounded-[24px] sm:rounded-[24px] w-[108px] h-9  border-1 lg:rounded-[42px] text-white xs:text-[12px] xs:mt-[24px] sm:text-[14px] sm:mt-[24px] text-[14px] mt-[52px] flex items-center justify-center"
                >
                  <div className="bg-black xs:w-[92px] xs:h-[32px] sm:w-[92px] sm:h-[32px] xs:rounded-[24px] sm:rounded-[24px] w-[104px] h-8 rounded-[42px] border-none flex justify-center items-center">
                    Explore
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="absolute bottom-0 w-full h-auto flex flex-grow">
          <footer className="w-full h-[84px] bg-black text-white font-SubDetail text-[12px] lg:px-[120px]  relative flex items-center lg:justify-between z-50">
            <ul className="flex flex-row gap-7">
              <li>
                <span className="font-SemiTitle">CEO:</span> <span>박용건</span>
              </li>
              <li>
                <span className="font-SemiTitle">
                  Business registration No:
                </span>{' '}
                634-86-01747
              </li>
              <li>
                <span className="font-SemiTitle">Address: </span>
                [Daegu] D267, 558-1, Gukchaebosang-ro, Jung-gu, Daegu, Republic
                of Korea
              </li>
              <li>
                <span className="font-SemiTitle" />
                [Seoul] 8F, 607, Yeongdong-daero, Gangnam-gu, Seoul, Korea
              </li>
            </ul>
            <span className="text-[13px]">© 2024 Mindshift, Inc.</span>
          </footer>
        </div>
      </section>
    </>
  );
};

export default Fourth;
